import Inputmask from "inputmask";

export function phoneMask(selector = 'input[data-role="phone"]') {
    let mask = "+7 (N99) 999-99-99";

    let hostParts = window.location.host.toLowerCase().split('.');

    if (hostParts.length === 3) {
        if (hostParts[0] === 'belarus') {
            mask = "+375 (99) 999-99-99";
        }
    }

    Inputmask({
        mask: mask,
        definitions: {
            "N": {
                validator: "[49]",
            }
        },
        showMaskOnHover: false,
        clearIncomplete: true
    }).mask($(selector).toArray());
}