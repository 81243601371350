import './jquery'
// import './includes/jquery.ui.touch-punch'
import Swiper, {Pagination} from 'swiper';
import {Navigation, Thumbs, Grid} from 'swiper'
import {phoneMask} from "./includes/phoneMask";
import {formComponent} from "./includes/formComponent";
import {initModals, openModal} from './includes/modal';
// import Cookies from 'js-cookie';
import { spincrement } from './includes/jquery.spincrement.min.js';
import Bowser from "bowser";

// "use strict";
$(document).ready(function () {
  browserVersion();
  cardInfoOnMobile();
  initModals();
  initFormComponent();
  phoneMask();
  stickyHeader();
  MobileMenu();
  StatusTab();
  CheckAgreements();

  $(".js-count").spincrement({
    thousandSeparator: " ",
    duration: 2000,
    leeway: 10
  });


  var bottom = new Swiper(".swiper--car-gallery_thumb", {
    spaceBetween: 10,
    slidesPerView: 4,
    freeMode: true,
    watchSlidesProgress: true,
  });

  var top = new Swiper(".swiper--car-gallery_large", {
    spaceBetween: 10,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    modules: [
      Navigation, Thumbs
    ],
    thumbs: {
      swiper: bottom,
    },
  });

  var carusel = new Swiper(".swiper--carusel-excellence", {
    freeMode: true,
    grabCursor: true,

    slidesPerView: 1,
    spaceBetween: 10,
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },

    modules: [
      Pagination
    ],
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },

  });


  var swiperGridResent = new Swiper(".swiper--grid-recent", {
    modules: [
      Navigation, Grid
    ],
    slidesPerView: 1,
    spaceBetween: 0,
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
      },
    },
    grid: {
      rows: 2,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  var swiperGridTrader = new Swiper(".swiper--grid-trader", {
    modules: [
      Navigation, Pagination
    ],
    slidesPerView: 1,
    spaceBetween: 0,
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
      },
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  var sravnenie = new Swiper(".swiper--sravnenie", {
    freeMode: true,
    grabCursor: true,

    slidesPerView: 2,
    breakpoints: {
      768: {
        slidesPerView: 3,
      },
      1024: {
        slidesPerView: 4,
      },
      1200: {
        slidesPerView: 5,
      },
    },

    modules: [
      Pagination,Navigation
    ],
    pagination: {
      el: ".swiper-pagination",
      dynamicBullets: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },

  });


  $(".slider_price").slider({
    range: "min",
    value: 300000,
    min: 100000,
    max: 3000000,
    step: 100000,
    slide: function (event, ui) {
      if (ui.value >= 1000000) {
        $(".slider_price").slider("option", "step", 100000);
      } else {
        $(".slider_price").slider("option", "step", 100000);
      }
      $("#pricedo").val(ui.value.toLocaleString('ru') + " р.");
      $("#pricedonum").val(ui.value);
    },
    change: function (event, ui) {
      $("#pricedo").val(ui.value.toLocaleString('ru') + " р.");
      $("#pricedonum").val(ui.value);
    }
  }).slider("pips", {
    rest: "label",
  });

  $("#pricedo").val($(".slider_price").slider("value").toLocaleString('ru') + " р.");
  $("#pricedonum").val($(".slider_price").slider("value"));


  $('.filter__main .ui-slider-label').each(function () {
    var $price = $(this).data('value');
    if ($price == 100000) {
      $(this).text('100 тыс.')
    } else if ($price == 500000) {
      $(this).text('500 тыс.')
    } else if ($price == 1000000) {
      $(this).text('1 млн.')
    } else if ($price == 1500000) {
      $(this).text('1.5 млн.')
    } else if ($price == 2000000) {
      $(this).text('2 млн.')
    } else if ($price == 2500000) {
      $(this).text('2.5 млн.')
    } else if ($price == 3000000) {
      $(this).text('3 млн.')
    }
  });

  $(document).on('submit', '#js-main-filter', function () {
    var catalog, price, url;
    catalog = $(this).attr('action');
    url = $(this).find('#car_type').val();
    price = $(this).find('#pricedonum').val();
    // url = '/' + catalog + '' + price + '/' + url;
    url = 'price/' + price + url;


    window.location.href = url;
    console.log(url);
    return false;
  });


});


function CheckAgreements() {
  $('input#check-agreements').on('change', function(){
    if($(this).prop('checked')) {
      $(this).closest( 'form' ).find('.submit').attr('disabled', false);
    } else {
      $(this).closest( "form" ).find('.submit').attr('disabled', true);
    }
  });
}

function MobileMenu() {
  $(document).on('click touch', '.off-canvas-toggle', function () {
    $('#sidebar-open').toggleClass('active');
  });

  $(document).on('click touch', '.off-canvas-overlay', function () {
    $('#sidebar-open').removeClass('active');
  });
}

function StatusTab() {
  $(document).on('click', '.model__status__btn a', function () {
    $('.model__status__btn a').removeClass('is-active');
    let id = $(this).attr('href');
    $('.model__status__tab').hide();
    $(id).show();
    $(this).addClass('is-active');
    return false;
  });
}

function initFormComponent() {
  /** all form actions */
  formComponent.init();
  formComponent.onBeforeValidate = function (context) {
    $('.field-error', context.$form).remove();
  };
  formComponent.onElementFail = function (context) {
    let errorClass = context.$element.data('error-class');
    let errorMessage = context.$element.data('error-message');
    if (errorClass) {
      context.$element.addClass(errorClass);
    }

    if (errorMessage) {
      let elname = context.$element.attr('name');
      let $errmsg = $(`<div class="${elname} field-error">${errorMessage}</div>`)
      context.$element.after($errmsg);
    }

    context.$element.one('focus', function () {
      if (errorClass) {
        context.$element.removeClass(errorClass);
      }
      if (errorMessage) {
        $('.field-error', context.$form).remove();
      }
    });
  };
  formComponent.onAjaxComplete = function (context) {
    try {
      try {
        ym(91491061, 'reachGoal', 'form_send');
      } catch (e) {
        console.error(e);
      }
      context.$form[0].reset();
      openModal(context.response.html);
    } catch (e) {
      console.error(e);
    }
  }
  formComponent.onAjaxFail = function (context) {
    try {
      context.$form[0].reset();
      let $error = $('<div class="modal-content-simple"><button class="modal-close" aria-label="close">×</button><div>Произошла ошибка, пожалуйста повторите попытку</div></div>')
      openModal($error);
    } catch (e) {
      console.error(e);
    }
  }
  formComponent.injectData = function (context) {
    let comment = '';
    Object.values(context.data).forEach(function (e, i) {
      if (e.name === 'mark') {
        comment += `Марка: ${e.value}. `;
      }
      if (e.name === 'model') {
        comment += `Модель: ${e.value}. `;
      }
      if (e.name === 'year') {
        comment += `Год: ${e.value}. `;
      }
      if (e.name === 'engine_gear') {
        comment += `Тип движка и кпп: ${e.value}. `;
      }
      if (e.name === 'volume') {
        comment += `объем: ${e.value}. `;
      }
      if (e.name === 'run') {
        comment += `Пробег: ${e.value}. `;
      }
      if (e.name === 'email') {
        comment += `E-mail: ${e.value}. `;
      }
      if (e.name === 'price') {
        comment += `Цена: ${e.value}. `;
      }
    })

    /* if comment field already exists */
    Object.values(context.data).forEach(function (e, i) {
      if (e.name === 'comment') {
        if (e.value) e.value += '. ';
        e.value += comment;
        comment = '';
      }
    });
    /* if no exists */
    if (comment) {
      context.data.push({
        name: 'comment',
        value: comment
      });
    }
    /*  */
    context.data.push({
      name: 'js',
      value: 1
    });
  }
  /** -- */
}
function browserVersion() {
  try {
    window.Bowser = Bowser.getParser(window.navigator.userAgent);
    $('body')
      .addClass(window.Bowser.getOSName().toLowerCase())
      .addClass(window.Bowser.getPlatform().type)
      .addClass(window.Bowser.getBrowserName().toLowerCase())
      .addClass(`v${window.Bowser.getBrowserVersion().replace(/\..+/g, '')}`)
  } catch (e) {
    console.error(e);
  }
}
function cardInfoOnMobile() {
  if(window.Bowser.getPlatform().type === 'mobile') {
    $(document).on('click', '.card', function () {
      $(this).toggleClass('opened');
    })
  }
}
function stickyHeader() {
$(window).scroll(function(){
  if ($(window).scrollTop() >= 400) {
    $('.main-header').addClass('sticky-header');
  }
  else if ($(window).scrollTop() <= 350) {
    $('.main-header').removeClass('sticky-header');
  }
});
}
