import $ from 'expose-loader?exposes=$,jQuery!jquery';

/** FIX:
 *  Added non-passive event listener to a scroll-blocking '' event.
 *  Consider marking event handler as 'passive' to make the page more responsive.
 *  */

function jQueryPassive(eventName) {
  return {
    setup: function (_, ns, handle) {
      this.addEventListener(eventName, handle, {passive: true});
    }
  }
}

jQuery.event.special.touchstart = jQueryPassive('touchstart');
jQuery.event.special.touchmove = jQueryPassive('touchmove');
jQuery.event.special.mousewheel = jQueryPassive('mousewheel');
