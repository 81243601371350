const formComponent = {
  forms: {},
  init: function () {
    $(document).on('submit', '.ajax-form', formComponent.beforeSubmit);
    $(document).on('click', '.ajax-form .submit', formComponent.triggerSubmit);
  },
  triggerSubmit: function () {
    $(this).parents('form').trigger('submit', {target: this});
  },
  beforeSubmit: function (e, ex) {
    e.preventDefault();
    let $form = $(this);
    let $button;
    if (ex && ex.target) {
      $button = $(ex.target);
    } else {
      $button = $(e.target);
    }

    let context = {
      $form: $form,
      $button: $button,
      data: null
    };

    formComponent.fn.eventCall('submit', context);
  },
  submit: function (context) {
    try {
      formComponent.fn.eventCall('onBeforeValidate', context);
      if (formComponent.validate(context)) {
        let $form = context.$form;
        let $button = context.$button;
        let isButton = context.$button.is('button');
        if (isButton) {
          $button.prop("disabled", true);
        }

        let postParams = {
          url: $form.attr('action'),
        }
        context.data = $form.serializeArray();
        context.data.push({name: "loc_host", value: window.location.host})
        context.data.push({name: "loc_path", value: window.location.pathname})
        context.data.push({name: "loc_search", value: window.location.search})
        // postParams['contentType'] = false;
        // postParams['processData'] = false;

        formComponent.fn.eventCall('injectData', context);

        postParams.data = context.data;

        $.post(postParams)
          .then(function (response) {
            context.response = response;
            formComponent.fn.eventCall('onAjaxComplete', context);
          })
          .fail(function (r, s, e) {
            context.r = r;
            context.s = s;
            context.e = e;
            formComponent.fn.eventCall('onAjaxFail', context);
          })
          .always(function () {
            formComponent.fn.eventCall('onAjaxAlways', context);
            if (isButton) {
              $button.prop("disabled", false);
            }
          });

      } else {
        formComponent.fn.eventCall('onValidateFail', context);
      }
    } catch (e) {
      console.error(e);
    }
  },
  validate: function (context) {
    let $elements = $('input,textarea,select', context.$form);
    let passed = true;

    for (let i = 0; i < $elements.length; i++) {
      let $element = $($elements[i]);
      let value = $element.val();
      let required = $element.attr('aria-required') !== undefined;
      if (
        required && !formComponent.fn.required(value)
      ) {
        passed = false;
        context.$element = $element;
        formComponent.fn.eventCall('onElementFail', context);
        break;
      }
    }

    return passed;
  },
  fn: {
    eventCall: function (method, context) {
      let formId = context.$form.attr('id');
      let uniq = formComponent.forms[formId];
      if (uniq && uniq[method]) {
        uniq[method](context);
      } else {
        formComponent[method](context);
      }
    },
    isEmpty: function (value) {
      return value === null || value === undefined || ($.isArray(value) && value.length === 0) || value === '';
    },
    required: function (value, messages, options) {
      let valid = false;
      let isString = typeof value == 'string' || value instanceof String;
      if (!formComponent.fn.isEmpty(isString ? $.trim(value) : value)) {
        valid = true;
      }

      return valid;
    }
  },
  onBeforeValidate: function (context) {

  },
  onElementFail: function (context) {
    console.error('onElementFail', context);
  },
  onValidateFail: function (context) {
    console.error('onValidateFail', context);
  },
  onAjaxComplete: function (context) {
    console.info('onAjaxComplete', context);
  },
  onAjaxFail: function (context) {
    console.error('onAjaxFail', context);
  },
  onAjaxAlways: function (context) {
    console.info('onAjaxAlways', context);
  },
  injectData: function (context) {

  }
};




export {formComponent}

// default init function
// function initFormComponent() {
//   formComponent.init();
//   formComponent.onBeforeValidate = function (context) {
//     $('.field-error', context.$form).remove();
//   };
//   formComponent.onElementFail = function (context) {
//     let errorClass = context.$element.data('error-class');
//     let errorMessage = context.$element.data('error-message');
//     if(errorClass) {
//       context.$element.addClass(errorClass);
//     }
//
//     if(errorMessage) {
//       let elname = context.$element.attr('name');
//       let $errmsg = $(`<div class="${elname} field-error">${errorMessage}</div>`)
//       context.$element.after($errmsg);
//     }
//
//     context.$element.one('focus', function () {
//       if(errorClass) {
//         context.$element.removeClass(errorClass);
//       }
//       if(errorMessage) {
//         $('.field-error', context.$form).remove();
//       }
//     });
//   };
//   formComponent.onAjaxComplete = function (context) {
//     try {
//       context.$form[0].reset();
//     } catch (e) {
//       console.error(e);
//     }
//   }
//   formComponent.onAjaxFail = function (context) {
//     try {
//       context.$form[0].reset();
//     } catch (e) {
//       console.error(e);
//     }
//   }
// }
