import {phoneMask} from "./phoneMask";

export function initModals() {
  $(document).on('click', '.modal [aria-label="close"]', function (e) {
    e.preventDefault();
    let $modal = $(this).parents('.modal');
    let $content = $('.modal-content', $modal);
    $content.empty();
    $('body').removeClass('modal-open');
    $modal.removeClass('active');
  });

  $(document).on('click', '[modal-url]', function (e) {
    e.preventDefault();
    let url = $(this).attr('modal-url');
    $.post(url, function (html) {
      openModal(html);
    })
  })
}

export function openModal($html) {
  if ($html) {
    let $modal = $('#modal-main');
    $('.modal-content', $modal).html($html);
    $('body').addClass('modal-open');
    $modal.addClass('active');
    phoneMask();
  }
}
